// noinspection JSIgnoredPromiseFromCall
import { StationsRequest } from "@/classes/requests/StationsRequest";

export default {
  async initializeStations(context) {
    // Get stations from API
    const websiteUrl = window.localStorage.getItem("websiteUrl");
    let stations = await StationsRequest.send(websiteUrl);

    // Reinitialize stations in the state and in the local storage
    // Since stations can always change in ePayTrak we cannot depend on the local storage
    window.localStorage.setItem("stations", JSON.stringify(stations));
    context.commit("setStationArray", stations);
  },

  // Initialize a new parameter cardReaderArray in the settings if the settings are initialized and cardReaderArray is not
  initializeCardReaders(context) {
    if (
      context.state.settings.cardReaderArray == null ||
      context.state.settings.cardReaderArray === undefined
    ) {
      let newSettings = {
        ...context.state.settings,
        cardReaderArray: ["SRED", "MED"],
      };
      window.localStorage.setItem("settings", JSON.stringify(newSettings));
      context.commit("setSettings", newSettings);
    }
  },

  setStation(context, station) {
    let newSettings = {
      ...context.state.settings,
      stationId: station.stationId,
    };
    window.localStorage.setItem("settings", JSON.stringify(newSettings));
    context.commit("setSettings", newSettings);
  },

  setSettings(context, newSettings) {
    window.localStorage.setItem("settings", JSON.stringify(newSettings));
    context.commit("setSettings", newSettings);
  },

  setBasket(context, basket) {
    context.commit("setBasket", basket);
  },

  appendToBasket(context, basketItems) {
    const newBasket = context.state.basket.concat(basketItems);
    context.commit("setBasket", newBasket);
  },

  insertOrUpdateItem(context, newItem) {
    const existingBasket = context.state.basket;
    let existingCartItem = [];

    //check if the state has the cart items from ePayTrak
    //1. if the state doesn't have cart item from ePayTrak then add it to the state
    //2. if the state has cart item from ePayTrak then update this cart item in the state
    let existingItemIndex = -1;
    if (newItem.variantId != 0) {
      existingItemIndex = existingBasket.findIndex(
        (basketItem) => basketItem.variantId === newItem.variantId
      );
      //Get Cart Item if it has varingId and it is not venue map
      existingCartItem = existingBasket.filter(
        (basketItem) =>
          basketItem.variantId === newItem.variantId &&
          !basketItem.sku.toLowerCase().includes("row") &&
          !basketItem.sku.toLowerCase().includes("seat")
      );
    } else {
      existingItemIndex = existingBasket.findIndex(
        (basketItem) =>
          basketItem.productId == newItem.productId &&
          basketItem.sku == newItem.sku
      );
      existingCartItem = existingBasket.filter(
        (basketItem) =>
          basketItem.productId == newItem.productId &&
          basketItem.sku == newItem.sku
      );
    }

    const itemExists = existingItemIndex > -1;

    if (!itemExists) {
      // Insert into the basket
      context.commit("pushItem", { item: newItem });
    } else {
      //Update quantity if it is the same product and it is not venue map event
      if (existingCartItem.length > 0) {
        newItem.qty += existingCartItem[0].qty;
      }

      // Update the existing item in basket
      context.commit("overwriteItem", {
        item: newItem,
        itemIndex: existingItemIndex,
      });
    }
  },

  insertOrUpdateMultipleItems(context, items) {
    items.forEach((item) => {
      context.dispatch("insertOrUpdateItem", item);
    });
  },
};
