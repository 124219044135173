import { EdutrakAPIRequest } from "@/classes/requests/EdutrakAPIRequest";
import xmljs from "xml-js";

export class LoginRequest extends EdutrakAPIRequest {
  static async send(websiteUrl, username, password) {
    // prettier-ignore
    const bodyPayload =
      '<?xml version="1.0" encoding="utf-8"?><soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"><soap:Body><GetDoorLogin xmlns="http://tempuri.org/"><UserPassword>' +
      username +
      "|" +
      password +
      "</UserPassword></GetDoorLogin></soap:Body></soap:Envelope>";

    const response = await fetch(
      "https://" + websiteUrl + "/mpos/DoorLogin.asmx",
      {
        method: "POST",
        headers: {
          "Content-Type": "text/xml;charset=UTF-8",
        },
        body: bodyPayload,
      }
    );

    const xml = await response.text();

    // Format code
    const result = xmljs.xml2json(xml, { compact: true });
    const jsonResult = JSON.parse(result);
    const userId =
      jsonResult["soap:Envelope"]?.["soap:Body"]?.["GetDoorLoginResponse"]?.[
        "GetDoorLoginResult"
      ]?.["RequestedUser"]?.["UserId"]?.["_text"];

    // Return whether user is logged in
    return userId != null && userId != 0;
  }
}

window.LoginRequest = LoginRequest;
