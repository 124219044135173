<template>
  <v-main>
    <nav-bar title="Edutrak mPOS" :show-app-details="false" />

    <main-card :column-width="6">
      <form @submit.prevent="submitForm">
        <v-alert v-if="loginError != ''" color="red" dark>
          {{ loginError }}
        </v-alert>

        <h2 class="mb-5">Login</h2>

        <v-text-field
          label="website url"
          outlined
          id="website-url"
          name="website-url"
          v-model="websiteUrl"
        >
        </v-text-field>

        <v-alert
          v-if="websiteUrlError != ''"
          color="red"
          dark
          style="margin-top: -15px"
        >
          {{ websiteUrlError }}
        </v-alert>

        <v-text-field
          label="username"
          outlined
          id="username"
          name="username"
          v-model="username"
        >
        </v-text-field>

        <v-alert
          v-if="emailError != ''"
          color="red"
          dark
          style="margin-top: -15px"
        >
          {{ emailError }}
        </v-alert>

        <v-text-field
          id="password"
          label="password"
          outlined
          name="password"
          v-model="userPassword"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        >
        </v-text-field>

        <v-btn
          elevation="2"
          class="white--text"
          color="light-green darken-2"
          type="submit"
          block
        >
          Login
        </v-btn>
      </form>

      <div class="mt-10 mb-5 d-flex justify-center">
        <a href="/#/privacy-policy" target="_blank">Privacy Policy</a>
      </div>
    </main-card>
  </v-main>
</template>

<script>
import { LoginRequest } from "@/classes/requests/LoginRequest";
import NavBar from "@/components/NavBar";
import MainCard from "@/components/layout/MainCard";
import LoginDataMixin from "@/mixins/LoginDataMixin";

export default {
  name: "LoginView",
  components: { MainCard, NavBar },
  mixins: [LoginDataMixin],

  data() {
    return {
      // Note: This view writes websiteUrl and username, so don't use LoginDataMixin
      websiteUrl: "",
      username: "",
      userPassword: "",
      showPassword: false,
      websiteUrlError: "",
      emailError: "",
      loginError: "",
    };
  },

  methods: {
    validateWebsiteUrl() {
      this.websiteUrlError = "";
      var re =
        /^((?!http:\/\/|https:\/\/|www\.)([\da-z\/\.-]+)\.[a-zA-Z]{2,3})$/;
      if (this.websiteUrl.trim() == "") {
        return (this.websiteUrlError = "Website is required.");
      }
      if (!re.test(this.websiteUrl)) {
        this.websiteUrlError =
          "Invalid website URL. Please enter url that matches the pattern example.org. The url should not include http or https or www at the beginning.";
      }
    },

    validateEmail() {
      this.emailError = "";
      var re = /[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+([A-Za-z0-9]{2,4})$/;
      if (this.username.trim() == "") {
        return (this.emailError = "Email is required.");
      }
      if (!re.test(this.username)) {
        return (this.emailError = "Invalid email address.");
      }
    },

    async submitForm() {
      this.validateWebsiteUrl();
      this.validateEmail();
      if (this.websiteUrlError != "" || this.emailError != "") {
        return;
      }

      try {
        const isLoggedIn = await LoginRequest.send(
          this.websiteUrl,
          this.username,
          this.userPassword
        );
        window.localStorage.setItem("isLoggedIn", isLoggedIn);
        if (isLoggedIn) {
          window.localStorage.setItem("websiteUrl", this.websiteUrl);
          window.localStorage.setItem("username", this.username);
          await this.$store.dispatch("initializeStations");
          this.$router.push("/main-menu");
        } else {
          this.loginError = "Login error. Please try again.";
        }
      } catch (err) {
        this.loginError = "Login error. Please try again.";
        console.log(err);
      }
    },
  },
};
</script>
