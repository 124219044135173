import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
    meta: { requiresAuth: false },
  },
  {
    path: "/main-menu",
    name: "main-menu",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "main-menu" */ "../views/MainMenuView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/show-listing",
    name: "show-listing",
    component: () =>
      import(
        /* webpackChunkName: "show-listing" */ "../views/ShowListingView.vue"
      ),
    meta: { requiresAuth: true },
  },

  {
    path: "/product-details/:productId",
    name: "product-details",
    component: () =>
      import(
        /* webpackChunkName: "product-details" */ "../views/ProductDetailsView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/categories-and-products/:catalogNodeId",
    name: "categories-and-products/",
    component: () =>
      import(
        /* webpackChunkName: "categories-and-products" */ "../views/CategoriesAndProductsView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/venue-map/:productId",
    name: "venue-map",
    component: () =>
      import(/* webpackChunkName: "venue-map" */ "../views/VenueMapView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/payment",
    name: "payment",
    component: () =>
      import(/* webpackChunkName: "payment" */ "../views/PaymentView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/ticket-price-selection/:productId",
    name: "ticket-price-selection",
    component: () =>
      import(
        /* webpackChunkName: "ticket-price-selection" */ "../views/TicketPriceSelectionView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/edit-order-items",
    name: "edit-order-items",
    component: () =>
      import(
        /* webpackChunkName: "edit-order-items" */ "../views/EditOrderItemsView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/search-products",
    name: "search-products",
    component: () =>
      import(
        /* webpackChunkName: "search-products" */ "../views/SearchProductsView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/door-control",
    name: "door-control",
    component: () =>
      import(
        /* webpackChunkName: "door-control" */ "../views/DoorControlView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/order-lookup",
    name: "order-lookup",
    component: () =>
      import(
        /* webpackChunkName: "order-lookup" */ "../views/OrderLookupView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/will-call",
    name: "will-call",
    component: () =>
      import(
        /* webpackChunkName: "will-call" */ "../views/ShowListingView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/will-call/:productId",
    name: "will-call-product",
    component: () =>
      import(/* webpackChunkName: "will-call" */ "../views/WillCallView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/sales-report",
    name: "sales-report",
    component: () =>
      import(
        /* webpackChunkName: "sales-report" */ "../views/ShowListingView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/cash-drawer-report",
    name: "cash-drawer-report",
    component: () =>
      import(
        /* webpackChunkName: "sales-report" */ "../views/CashDrawerReport.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(
        /* webpackChunkName: "sales-report" */ "../views/SettingsView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/sales-report/:productId",
    name: "sales-report",
    component: () =>
      import(
        /* webpackChunkName: "sales-report" */ "../views/SalesReportView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/receipt/:orderId",
    name: "receipt",
    component: () =>
      import(/* webpackChunkName: "receipt" */ "../views/ReceiptView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicyView.vue"
      ),
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  routes,
});

// Check the status of the API key before routing
router.beforeEach((to, from, next) => {
  document.title = to.name;
  const authRequired = to.matched.some((route) => route.meta.requiresAuth);
  const isLoggedIn = window.localStorage.getItem("isLoggedIn") === "true";
  if (authRequired && !isLoggedIn) {
    next({ path: "/" }); // Redirect to login
  } else if (!authRequired && isLoggedIn) {
    next({ path: "/main-menu" }); // Redirect to first logged in page
  } else {
    next(); // Route as normal
  }
});

export default router;
