<template>
  <v-container class="mt-8">
    <v-row>
      <v-spacer />
      <v-col cols="12" :lg="columnWidth">
        <v-card elevation="2" class="pa-5">
          <slot></slot>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    columnWidth: { type: Number, default: 10 },
  },
};
</script>
