import Vue from "vue";
import Vuex from "vuex";
import state from "@/store/state";
import getters from "@/store/getters";
import actions from "@/store/actions";
import mutations from "@/store/mutations";
//import basket from "@/store/modules/basket";

Vue.use(Vuex);

export default new Vuex.Store({
  //modules: { basket },
  state,
  getters,
  actions,
  mutations,
});
