<template>
  <v-card class="light-green elevation-2">
    <v-row class="no-gutters">
      <v-col v-if="showAppDetails" cols="12" sm="3" md="4" lg="4" class="mt-4">
        <div class="d-flex ml-5 flex-wrap centralAlignment">
          <div class="mb-3">
            <slot name="buttons" />
          </div>
          <v-btn
            v-if="currentRoute != 'login' && currentRoute != 'main-menu'"
            href="/#/main-menu"
            color="light-green darken-2"
            class="white--text ml-2 mb-2"
          >
            Home
          </v-btn>
        </div>
      </v-col>

      <v-col
        class="titleMargin"
        cols="12"
        :sm="smSize"
        :md="mdSize"
        :lg="lgSize"
        :class="titleAlignment"
      >
        <span class="text-h5 ml-4">
          <strong>{{ title }} </strong>
        </span>
      </v-col>

      <v-col
        v-if="showAppDetails"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="d-flex align-center justify-center"
        style="min-width: 290px"
      >
        <div class="d-flex flex-column align-end">
          <small>
            <strong>EduTrak mPOS {{ version }}</strong>
          </small>
          <small>{{ websiteUrl }}</small>
          <small>{{ username }}</small>
        </div>
        <div class="d-flex justify-center">
          <v-btn icon href="/#/edit-order-items" v-if="cartItems.length > 0">
            <div class="mt-8 ml-7">{{ totalQuantity }}</div>
            <v-icon x-large> mdi-cart-variant </v-icon>
          </v-btn>
          <v-btn icon href="/#/settings" class="ml-6">
            <v-icon x-large> mdi-cog </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import LoginDataMixin from "@/mixins/LoginDataMixin";
import { sum } from "lodash";

export default {
  mixins: [LoginDataMixin],

  computed: {
    currentRoute() {
      return this.$route.name;
    },

    smSize() {
      if (this.showAppDetails) {
        return 3;
      }
      return 12;
    },

    mdSize() {
      if (this.showAppDetails) {
        return 4;
      }
      return 12;
    },

    lgSize() {
      if (this.showAppDetails) {
        return 5;
      }
      return 12;
    },

    titleAlignment() {
      if (this.showAppDetails) {
        return "d-flex centralAlignment";
      }
      return "d-flex loginScreenAlignment mb-2";
    },

    cartItems() {
      return this.$store.state.basket;
    },

    totalQuantity() {
      return sum(this.cartItems.map((item) => item.qty));
    },
  },

  props: {
    title: { type: String, required: true },
    showAppDetails: { type: Boolean, default: true },
  },

  data() {
    // noinspection JSUnresolvedVariable
    return {
      version: GIT_DESCRIBE.tag,
    };
  },
};
</script>

<style lang="scss">
.NavBar {
  width: 100%;
}

.loginScreenAlignment {
  justify-content: center;
}

.titleMargin {
  margin-top: 15px;
}

@media screen and (max-width: 596px) {
  .titleMargin {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .centralAlignment {
    justify-content: center;
  }
}
</style>
