import { EdutrakAPIRequest } from "@/classes/requests/EdutrakAPIRequest";
import { formatImageUrl } from "@/classes/helpers/AjaxHelper";
import xmljs from "xml-js";

export class StationsRequest extends EdutrakAPIRequest {
  static async send(websiteUrl) {
    const bodyPayload =
      '<?xml version="1.0" encoding="utf-8"?><soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"><soap:Body><getMposStations xmlns="http://tempuri.org/" /></soap:Body></soap:Envelope>';
    const fullWebsiteUrl = "https://" + websiteUrl;
    const response = await fetch(
      fullWebsiteUrl + "/mpos/getmposstations.asmx",
      {
        method: "POST",
        headers: {
          "Content-Type": "text/xml;charset=UTF-8",
        },
        body: bodyPayload,
      }
    );

    const xml = await response.text();

    const result = xmljs.xml2json(xml, { compact: true });

    const jsonResult = JSON.parse(result);

    let stationsArray =
      jsonResult["soap:Envelope"]?.["soap:Body"]?.["getMposStationsResponse"]?.[
        "getMposStationsResult"
      ]?.["MposStation"];

    stationsArray = Array.isArray(stationsArray)
      ? stationsArray
      : [stationsArray];

    return stationsArray
      .map((stationData) => ({
        stationId: stationData?.["StationId"]?.["_text"],
        stationName: stationData?.["StationName"]?.["_text"],
        address1: stationData?.["Address1"]?.["_text"],
        address2: stationData?.["Address2"]?.["_text"],
        city: stationData?.["City"]?.["_text"],
        state: stationData?.["State"]?.["_text"],
        postalCode: stationData?.["PostalCode"]?.["_text"],
        phone: stationData?.["Phone"]?.["_text"],
        email: stationData?.["Email"]?.["_text"],
      }))
      .filter(
        (stationData) =>
          stationData.stationName != undefined &&
          stationData.address1 != undefined &&
          stationData.stationId != 0
      );
  }
}
