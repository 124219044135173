import Vue from "vue";

export default {
  setSettings(state, settings) {
    Vue.set(state, "settings", settings);
  },

  setStationArray(state, stations) {
    Vue.set(state, "stations", stations);
  },

  setBasket(state, basket) {
    Vue.set(state, "basket", basket);
  },

  pushItem(state, { item }) {
    state.basket.push(item);
  },

  overwriteItem(state, { item, itemIndex }) {
    Vue.set(state.basket, itemIndex, item);
  },

  setCartItems(state, cartItems) {
    Vue.set(state, "cartItems", cartItems);
  },

  removeCartItem(state, itemIndex) {
    state.basket.splice(itemIndex, 1);
  },
};
