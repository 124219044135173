export default {
  data() {
    return {
      websiteUrl: null,
      username: null,
    };
  },

  mounted() {
    this.websiteUrl = window.localStorage.getItem("websiteUrl");
    this.username = window.localStorage.getItem("username");
  },
};
