// noinspection JSUnresolvedVariable
import PrinterSettingType from "@/constants/PrinterSettingType";

const savedSettings = JSON.parse(window.localStorage.getItem("settings"));
const savedStations = JSON.parse(window.localStorage.getItem("stations"));

export default {
  // These are cart items, that will be checked out eventually
  basket: [],
  //web-mPOS settings
  settings: savedSettings || {
    printer: PrinterSettingType.DESKTOP,
    cardReaderArray: ["SRED", "MED"],
    scanner: null,
    stationId: null,
  },

  stations: savedStations || [],
};
