export default {
  getItemByVariantId(state) {
    return (variantId) => {
      state.basket.find((item) => item.variantId === variantId);
    };
  },

  cartItemsLoaded(state) {
    return state.cartItems != null;
  },

  selectedOrDefaultStationId(state) {
    const selectedStation = state.stations.find(
      (stationItem) => stationItem.stationId == state.settings.stationId
    );

    if (state.settings.stationId != null && selectedStation != undefined) {
      return state.settings.stationId;
    } else if (state.stations.length > 0) {
      return state.stations[0].stationId;
    }

    return null;
  },

  /**
   * Get the selected station object. If selected station id is null or invalid,
   * will default to first station, or null if no stations available.
   * @param state
   * @param getters
   * @returns {null|*}
   */
  selectedStation(state, getters) {
    const selectedStation = state.stations.find(
      (stationItem) =>
        stationItem.stationId == getters.selectedOrDefaultStationId
    );
    if (selectedStation === undefined) {
      if (state.stations.length > 0) {
        return state.stations[0];
      } else {
        return null;
      }
    }
    return selectedStation;
  },
};

/**
 * Inside of a component
 * this.$store.getters.basket // = [...]
 * this.$store.getters.getItemByVariantId // function
 * this.$store.getters.getItemByVariantId(item.variantId)
 */
