import { camelToSnake, snakeToCamel } from "@/classes/helpers/StringHelper";
import { isObject, isArray } from "lodash";

/**
 * Recursively format all object keys in a given data structure.
 * @param {any} data
 * @param {function(string):string} formatter
 * @returns {any}
 */
export const formatKeys = function (data, formatter) {
  if (isObject(data)) {
    const n = {};

    Object.keys(data).forEach((k) => {
      n[formatter(k)] = formatKeys(data[k], formatter);
    });

    return n;
  } else if (isArray(data)) {
    return data.map((i) => {
      return formatKeys(i, formatter);
    });
  }

  return data;
};

export const keysToCamel = (data) => formatKeys(data, snakeToCamel);

export const keysToSnake = (data) => formatKeys(data, camelToSnake);

export const formatImageUrl = (imageUrl, fullWebsiteUrl) => {
  return imageUrl == undefined || imageUrl == null
    ? ""
    : fullWebsiteUrl + imageUrl.replace("~", "");
};

export default {
  formatKeys, // This shouldn't be needed, but is available for edge cases
  keysToCamel,
  keysToSnake,
  formatImageUrl,
};
